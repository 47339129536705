import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";
import { apiPath } from "@/config/apiPath";

const loadFilterValue = () => {
    const filterValue = JSON.parse(localStorage.getItem("filterValue"));
    return filterValue
        ? filterValue
        : {
            filter: false,
            country: "",
            state: "",
            city: "",
        };
};
const state = {
    pageAttributes: {
        page: 1,
        itemsPerPage: 10,
    },
    jobApplications: [],
    filterValue: loadFilterValue(),
    search: "",
    searchLoading: false,
    startSearch: false,
    closeSearchBar: false,
};

const getters = {
    getFilterValue: (state) => {
        return state.filterValue;
    },
    getAllJobApplications: (state) => state.jobApplications,
};

const mutations = {
    setSearch(state, value) {
        state.search = value;
    },
    setSearchLoading(state, value) {
        state.searchLoading = value;
    },
    setStartSearch(state, value) {
        state.startSearch = value;
    },
    setCloseSearchBar(state, value) {
        state.closeSearchBar = value;
    },
    setFilterValue: (state, payload) => {
        state.filterValue = payload;
        localStorage.setItem("filterValue", JSON.stringify(payload));
    },
    setPageAttributes(state, value) {
        state.pageAttributes = value
    },
    setAllJobApplications: (state, jobapplications) =>
        (state.jobApplications = jobapplications),

    doNothing: (state) => state,
    updateJobApplication: (state, updatedJobAppllicant) => {
        state.jobApplications.forEach((jobApplicant) => {
            if (jobApplicant.id == updatedJobAppllicant.id) {
                // Update Data
                jobApplicant.status = updatedJobAppllicant.status;
            }
        });
    },
    removeJobApplicatio: (state, id) => {
        state.jobApplications = state.jobApplications.filter(
            (jobApplication) => jobApplication.id !== id
        );
    },
};

const actions = {
    updateSearch({ commit }, value) {
        commit("setSearch", value);
    },
    updateSearchLoading({ commit }, value) {
        commit("setSearchLoading", value);
    },
    updateStartSearch({ commit }, value) {
        commit("setStartSearch", value);
    },
    updateCloseSearchBar({ commit }, value) {
        commit("setCloseSearchBar", value);
    },

    updateFilterValue: ({ commit }, filterValue) => {
        commit("setFilterValue", filterValue);
    },
    updatePageAttributes({ commit }, value) {
        commit("setPageAttributes", value);
    },
    //   /**
    //    * @description Get All Jobs Applicants Info
    //    * @param {Object} data - Jobs Applicants Object
    //    * @return Promise
    //    */
    async fetchAllJobApplications({ commit }, options) {
        return new Promise((resolve, reject) => {
            let params = {};
            if (options.all) {
                params = options;
            } else {
                const { sortBy, sortDesc, page, itemsPerPage } = options;
                let sort_by = sortBy[0];
                let sort_order = sortDesc[0] ? "DESC" : "ASC";
                params = {
                    page: page,
                    per_page: itemsPerPage,
                    sort_by: sort_by,
                    sort_order: sort_order,
                };
            }
            let url = getQueryString(params).substring(1);
            axios
                .get(apiPath.jobApplications.getAllJobApplications+`?${url}`)
                .then(async (res) => {
                    if (res.data.success) {
                        await commit("setAllJobApplications", res.data?.data?.data);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },


    //   /**
    //    * @description Remove Job Applicant
    //    * @param {Number} id - Applicant Id
    //    * @returns Promise
    //    */
    async removeJobApplication({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(apiPath.jobApplications.removeJobApplication+`?${id}`)
                .then(async (res) => {
                    if (res.data.success) {
                        await commit("removeIndustry", id);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },


    //   /**
    //    * @description Update job applicants status
    //    * @param {Object} data - Update job applicants Object
    //    * @returns Promise
    //    */
    async updateJobApplicationStatus({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios
                .put(apiPath.jobApplications.updateJobApplication+`${data.id}`, data)
                .then(async (res) => {
                    if (res.data.success) {
                        await commit("updateJobApplication", data);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    /**
* @description Filter job applicants
* @param {Number} id -  state, city, country
* @returns Promise
*/
    async filterJobApplicants({ commit }, options) {
        return new Promise((resolve, reject) => {
            let params = {};
            if (options.all) {
                params = {
                    ...options,
                    state: state.filterValue.state,
                    city: state.filterValue.city,
                    country: state.filterValue.country,
                };
            } else {
                const { sortBy, sortDesc, page, itemsPerPage } = options;
                let sort_by = sortBy[0];
                let sort_order = sortDesc[0] ? "DESC" : "ASC";

                params = {
                    page: page,
                    per_page: itemsPerPage,
                    sort_by: sort_by,
                    sort_order: sort_order,
                    state: state.filterValue.state,
                    city: state.filterValue.city,
                    pincode: state.filterValue.pincode,
                    country: state.filterValue.country,
                };
            }
            let url = getQueryString(params).substring(1);

            axios
                .get(apiPath.jobApplications.filterJobApplications+`?${url}`)
                .then(async (res) => {
                    if (res.data.success) {
                        await commit("setAllJobApplications", res.data?.data?.data);
                        resolve(res);
                    }
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    /**
       * @description On download button get data in excel sheet
       * @returns Promise
       */
    async getAllExcelSheetByDownload({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(apiPath.jobApplications.downloadJobApplications)
                .then((res) => {
                    resolve(res);
                    commit("doNothing");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
