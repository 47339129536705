<template>
  <v-app style="background-color: #f5f8fc">
    <!-- System Bar For Dev Env -->
    <AppSystemBar v-if="mode == 'DEV' || mode == 'STAGE'"></AppSystemBar>
    <!-- System Bar For Dev Env -->

    <AppToolbarVue v-if="$store.state.authStore.userLoggedIn" />

    <AppDrawerVue v-if="$store.state.authStore.userLoggedIn" />

    <!-- Snackbar -->
    <AppSnackBarVue />
    <!-- Snackbar -->
    <!-- err dialog -->
    <AppEdialogVue />
    <!-- err dialog -->
    <!-- Loader -->
    <v-main class="fill-height" v-if="checkingStatus">
      <v-container fluid class="pa-0 ma-0 fill-height">
        <v-row>
          <v-col class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- Loader -->

    <!-- Router View  -->
    <v-main class="fill-height" v-else>
      <v-container fluid class="py-0 px-md-10 px-3 px-sm-5">
        <!-- fill-height  -->
        <v-row justify="center">
          <v-col :md="sidePanelStatus ? 9 : 12">
            <div class="pa-0">
              <v-slide-y-reverse-transition>
                <router-view v-show="show" style="height: 100%" />
              </v-slide-y-reverse-transition>
            </div>
          </v-col>
          <v-col v-show="sidePanelStatus" md="3">
            <div
              style="
                height: 88vh;
                background-color: white;
                width: 100%;
                border-radius: 12px;
              "
              class="pa-3"
            >
              <p>
                Features Comming Soon
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- Router View  -->
  </v-app>
</template>

<script>
import { jwtDecode } from "jwt-decode";
import router from "./router";

export default {
  name: "App",
  components: {
    AppSystemBar: () => import("@/components/core/AppSystemBar"),
    AppToolbarVue: () => import("./components/core/AppToolbar.vue"),
    AppDrawerVue: () => import("./components/core/AppDrawer.vue"),
    AppSnackBarVue: () => import("./components/core/AppSnackBar.vue"),
    AppEdialogVue: () => import("./components/core/AppEdialog.vue"),
  },
  data: () => ({
    show: false,
    mode: process.env.VUE_APP_MODE,
    checkingStatus: true,
  }),
  computed: {
    sidePanelStatus: {
      get() {
        return this.$store.state.leftSidePanelStatus;
      },
    },
  },
  async created() {
    this.show = true;
    this.checkingStatus = true;
    if (
      localStorage.getItem("accessToken") == null ||
      localStorage.getItem("userInfo") == null
    ) {
      // Update User Status to Logout
      this.$store.commit("authStore/changeUserState", false);
      this.checkingStatus = false;
    } else {
      // Update User Status to Logged in
      if (jwtDecode(localStorage.getItem("accessToken")).app_type == 0) {
        this.$store.commit("authStore/changeUserState", true);
        this.$store.commit(
          "authStore/setUser",
          JSON.parse(localStorage.getItem("userInfo"))
        );
        this.$store.commit(
          "authStore/setAccessToken",
          localStorage.getItem("accessToken")
        );
        this.checkingStatus = false;
      } else {
        this.$store.commit("authStore/changeUserState", false);
        this.$store.commit("authStore/clearAll");
        router.push("/login");
        this.checkingStatus = false;
      }
    }
  },
};
</script>
