<template>
  <v-main class="ma-0 pa-0">
    <v-img
      class="white--text align-center justify-center mb-4"
      :src="require('@/assets/img/home.png')"
      style="border-radius: 12px"
    >
      <v-container fluid class="ma-4">
        <v-row justify="center" align="center">
          <v-col md="6" cols="12" class="">
            <p class="mb-0" style="font-size: 220%; color: black">
              Hello
              <strong
                >{{
                  $store.state.authStore.currentUser?.full_name || "User"
                }}!</strong
              >
            </p>
            <p class="mt-n1" style="color: black">
              Hello
              {{ $store.state.authStore.currentUser?.full_name || "User" }},
              How's your day going?
            </p>
          </v-col>
          <v-col md="6" cols="12" class="text-right pr-10">
            <p style="color: white; font-size: 300%" class="mb-0">
              {{ getCurrentTime() }}
            </p>
            <p class="mb-0 mt-n2">{{ new Date() }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
    <!-- Summary Blocks -->
    <v-container fluid class="px-2">
      <v-row>
        <v-col
          v-for="(item, index) in summery"
          :key="index"
          :md="item.size"
          sm="4"
          cols="6"
          class="ma-0 pa-0 mb-2 px-1"
          @click="viewPage(item.route)"
        >
          <v-container
            class="pa-5"
            fluid
            style="
              background-color: white;
              border-radius: 12px;
              cursor: pointer;
              min-height: 80px;
            "
          >
            <v-row justify="center" align="center">
              <v-col md="6" cols="6">
                <p style="font-size: 90%" class="mb-0">{{ item.label }}</p>
              </v-col>
              <v-col md="6" class="text-right" cols="6">
                <p
                  class="mb-0"
                  style="font-size: 200%; white-space: nowrap; max-width: 100%"
                >
                  {{ formatNumber(item.value) }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <!-- Summary Blocks -->
  </v-main>
</template>

<script>
export default {
  name: "HomePage",
  data: () => ({
    summery:[],
    summaryData: {},
    summaryBlocks: [
      {
        label: "Total Users",
        size: "2",
        dataKey: "users",
        route: "/users",
      },
      {
        label: "Total Jobs",
        size: "2",
        dataKey: "jobs",
        route: "/jobs",
      },
      {
        label: "Total Brands",
        size: "2",
        dataKey: "brands",
        route: "/brands",
      },
      {
        label: "Total Properties",
        size: "2",
        dataKey: "properties",
        route: "/properties",
      },
      {
        label: "Total Tags",
        size: "2",
        dataKey: "tags",
        route: "/tags",
      },
      {
        label: "Total Industries",
        size: "2",
        dataKey: "industries",
        route: "/industries",
      },
      {
        label: "Total Job Applications",
        size: "2",
        dataKey: "job_applicants",
        route: "/job-applicants",
      },
      {
        label: "Total Requirements",
        size: "2",
        dataKey: "brand_requirements",
        route: "/all-brand-requirements",
      },
      {
        label: "Brand Contacts",
        size: "2",
        dataKey: "brand_contacts",
        route: "/brand-contacts",
      },
      {
        label: "Total Brokers",
        size: "2",
        dataKey: "brokers",
        route: "/brokers",
      },
      {
        label: "Total Landlords",
        size: "2",
        dataKey: "landlords",
        route: "/landlords",
      },
      {
        label: "Total Offices",
        size: "2",
        dataKey: "offices",
        route: "/offices",
      },
    ],
  }),
  components: {},
  async mounted() {
    try {
      this.getSummaryData();
      // The rest of your initialization logic goes here
    } catch (error) {
      console.error("Error in mounted hook:", error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  },
  methods: {
    getCurrentTime() {
      var time = new Date();
      var res = time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return res;
    },
    getSummaryData() {
      this.$store
        .dispatch("summaryStore/fetchAllSummeryData")
        .then(async (res) => {
          if (res.data.success) {
            this.summaryData = res.data.data;
            await this.updateSummaryBlocks(this.summaryBlocks, this.summaryData)
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    updateSummaryBlocks(summaryBlocks, data) {
      this.summery = []
      summaryBlocks.forEach((block) => {
        if(Object.prototype.hasOwnProperty.call(data, block.dataKey)) {
          this.summery.push({
            label: block.label,
            size: block.size,
            dataKey: block.dataKey,
            route: block.route,
            value: data[block.dataKey]
          })
        }
      });
      return summaryBlocks;
    },
    formatNumber(num) {
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + "k";
      } else {
        return num;
      }
    },
    viewPage(route) {
      this.$router.push(route);
    },
  },
};
</script>
