import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const state = {
  visitSchedule: [],
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
};

const getters = {
  getAllVisitSchedule: (state) => state.visitSchedule,
};

const mutations = {
  setAllVisitSchedule: (state, proposal) =>
    (state.visitSchedule = proposal),
  doNothing: (state) => state,
  addToVisitSchedule: (state, proposal) =>
    state.visitSchedule.unshift(proposal),
  updateVisitSchedule: (state, updatedData) => {
    state.visitSchedule.forEach((propData) => {
      if (propData.id == updatedData.id) {
        propData.name = updatedData.name;
      }
    });
  },
  removeVisitSchedule: (state, id) => {
    state.visitSchedule.splice(id, 1);
  },
};

const actions = {
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All visit_schedulingss
   * @param {Number} data - visit_schedulingss Object
   * @returns Promise
   */
  async fetchAllVisitSchedule({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.schedule.getAllVisitSchedulings+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllVisitSchedule", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

 
  /**
   * @description Create New visit_schedulings
   * @param {Object} data - visit_schedulings Object
   * @returns Promise
   */
  createVisitSchedule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.schedule.createVisitScheduling, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("addToVisitSchedule", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update visit_schedulings Info
   * @param {Object} data - Update visit_schedulings Object
   * @returns Promise
   */
  updateVisitScheduleById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.schedule.updateVisitScheduling+`${data._id}`, data)
        .then(async (res) => {
          if (res.data.success) {
            commit("updateVisitSchedule", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Remove visit_schedulings
   * @param {Number} id - visit_schedulings Id
   * @returns Promise
   */
  async removeVisitScheduleById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.schedule.removeVisitScheduling + id, {})
        .then(async (res) => {
          if (res.data.success) {
            await commit("removeVisitSchedule", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
