import Vue from "vue";
import Vuex from "vuex";

import authStore from "./modules/auth.store";
import userStore from "./modules/user.store";
import roleStore from "./modules/role.store";
import officeStore from "./modules/office.store";
import permissionStore from "./modules/permission.store";
import landlordStore from "./modules/landlord.store";
import propertyStore from "./modules/property.store";
import otpStore from "./modules/otp.store";
import brandStore from "./modules/brand.store";
import brokerStore from "./modules/broker.store";
import brandRequirementStore from "./modules/brand-requirement.store";
import brandContactStore from "./modules/brand-contact.store";
import industryStore from "./modules/industry.store";
import tagStore from "./modules/tag.store";
import agpropAiStore from "./modules/agprop-ai.store";
import jobStore from "./modules/job.store";
import jobApplicationStore from "./modules/job-application-store.js";
import summaryStore from "./modules/summary.store";
import proposalStore from "./modules/proposal.store";
import propertyProposalRequestStore from "./modules/propertyProposalRequest.store";
import visitScheduleStore from "./modules/visit-schedule.store";
import brokerPropertiesStore from "./modules/brokerProperties.store";
import externalPropertyStore from "./modules/externalPropertyStore";
import smartSearchStore from "./modules/smartSearch.store";

//import brokerPropertiesStore from "./module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: true,
    leftSidePanelStatus: false,
    edialog: {
      isEdialogVisible: false,
      eMessage: "",
      eTitle: "",
    },
    snackbar: {
      isSnackBarVisible: false,
      snackBarMessage: "",
    },
  },
  getters: {
    getSnackbarMessage: (state) => state.snackbar.snackBarMessage,
    getEdialogMessage: (state) => state.edialog.eMessage,
    getEdialogTitle: (state) => state.edialog.eTitle,
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: (state) => (state.drawer = !state.drawer),
    toggleEdialog: (state) =>
      (state.edialog.isEdialogVisible = !state.edialog.isEdialogVisible),
    setEdialogMessage: (state, payload) => (state.edialog.eMessage = payload),
    setEdialogTitle: (state, payload) => (state.edialog.eTitle = payload),
    setEdialogVisible: (state, payload) =>
      (state.edialog.isEdialogVisible = payload),
    setSnackbarVisible: (state, payload) =>
      (state.snackbar.isSnackBarVisible = payload),
    toggleSnackbar: (state) =>
      (state.snackbar.isSnackBarVisible = !state.snackbar.isSnackBarVisible),
    setSnackbarMessage: (state, payload) =>
      (state.snackbar.snackBarMessage = payload),
    toggleLeftSidePanelStatus: (state) =>
      (state.leftSidePanelStatus = !state.leftSidePanelStatus),
  },
  actions: {},
  modules: {
    authStore,
    userStore,
    roleStore,
    officeStore,
    permissionStore,
    otpStore,
    landlordStore,
    propertyStore,
    brandStore,
    brokerStore,
    brandRequirementStore,
    brandContactStore,
    industryStore,
    tagStore,
    agpropAiStore,
    jobStore,
    jobApplicationStore,
    summaryStore,
    proposalStore,
    propertyProposalRequestStore,
    visitScheduleStore,
    brokerPropertiesStore,
    externalPropertyStore,
    smartSearchStore
  },
});
