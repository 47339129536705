import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";
const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  jobs: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAlljobs: (state) => state.jobs,
};

const mutations = {
  setAllJobs: (state, payload) => (state.jobs = payload),
  doNothing: (state) => state,
  addToJobs: (state, payload) => state.jobs.unshift(payload),
  updateJobs: (state, updatedData) => {
    state.jobs.forEach((job) => {
      if (job.id == updatedData.id) {
        job.name = updatedData.name;
      }
    });
  },

  removeJob: (state, id) => {
    state.jobs = state.jobs.filter((job) => job.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value
  }
};

const actions = {
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All jobs
   * @param {Number} data - jobs Object
   * @returns Promise
   */
  async fetchAllJobs({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.jobs.getAllJobs+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllJobs", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Search Job
   * @param {Number} data - jobs Object
   * @returns Promise
   */
  async searchJob({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }
      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.jobs.searchJobs+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllJobs", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create New Job
   * @param {Object} data - Job Object
   * @returns Promise
   */
  createJob({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.jobs.createJob, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("addToJobs", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update Job Info
   * @param {Object} data - Update Job Object
   * @returns Promise
   */
  updateJobsByID({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.jobs.updateJob+`${data._id}`, data)
        .then(async (res) => {
          if (res.data.success) {
            commit("updateJobs", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  getJobsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.jobs.getJobByID+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
 * @description Remove Job
 * @param {Number} id - Job Id
 * @returns Promise
 */
  async removeJobById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(apiPath.jobs.removeJob + id, {})
        .then(async (res) => {
          if (res.data.success) {
            await commit("removeJob", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description On download button get data in excel sheet
   * @returns Promise
   */
  async getAllExcelSheetByDownload({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.jobs.downloadJobs)
        .then((res) => {
          resolve(res);
          commit("doNothing");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
