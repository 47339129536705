/**
 * Vue Mixins
 * Author: Sonakshi Pandey
 * ES6 Module
 */

export default {
  methods: {
    /**
     * @description filter Request Object
     * @param {Object} data - Object
     * @returns {Object} Object
     */
    filterRequestBody(data) {
      for (const [key, value] of Object.entries(data)) {
        if (value === "" || value === null) {
          delete data[key];
        }
      }
      return data;
    },

    /**
     * @description get all sorted data by Name (property)
     * @param {Array} data - Array of JSON
     * @returns {Array} Sorted Array of JSON
     */
    sortByName: (data) =>
      data.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),

    /**
     * @description get name capital(property)
     * @param {Array} data - Array of JSON
     * @returns {Array} Sorted Array of JSON
     */
    capitalize(word) {
      return (
        word
          //.split("")
          .map((letter, index) =>
            index ? letter.toLowerCase() : letter.toUpperCase()
          )
          .join("")
      );
    },

    /**
     * @description check Permission
     * @param {Array} data - Array of JSON
     * @returns {Array} Sorted Array of JSON
     */
    checkPermission(permissions) {
      const b = this.$store.state.authStore.currentPermission.map(o=>o.key);
      for (const key of permissions) {
        if (b.includes(key)) {
          return true;
        }
      }
    },
  },
};
