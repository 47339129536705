import axios from "@/utils/axios";

const state = {
  currentUser: null,
};

const getters = {
  getUser: (state) => state.currentUser,
};

const mutations = {
  setUser: (state, payload) => (state.currentUser = payload),
  doNothing: (state) => state,
};

const actions = {
  /**
   * @description User if forget the password to login
   * @param {Object} data - email
   * @returns Promise
   */
  async sendEmailForForgotPassword(_, email) {
    return new Promise((resolve, reject) => {
      // Make an HTTP POST request to your API endpoint
      axios
        .post("/v1/auth/forgot-password", {
          email: email,
        })
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          }
        })
        // Handle the response as needed
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  /**
   * @description it will verify the OTP which user received on his email
   * @param {Object} data - email, OTP
   * @returns Promise
   */
  async verifyOtp(_, { email, otp }) {
    return new Promise((resolve, reject) => {
      // Make an HTTP POST request to your API endpoint
      axios
        .post("v1/auth/verify-otp", {
          email: email,
          otp: otp,
        })
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          }
        })
        // Handle the response as needed
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  /**
   * @description reset the password by entring new password
   * @param {Object} data - email, new password
   * @returns Promise
   */
  async resetPassword(_, { email, newPassword }) {
    return new Promise((resolve, reject) => {
      // Make an HTTP POST request to your API endpoint
      axios
        .put("v1/auth/reset-password", {
          email: email,
          password: newPassword,
        })
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          }
        })
        // Handle the response as needed
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
