import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  permissions: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllPermissions: (state) => state.permissions,
};

const mutations = {
  setAllPermissions: (state, payload) => (state.permissions = payload),
  doNothing: (state) => state,
  addToPermission: (state, payload) => state.permissions.unshift(payload),
  updatePermission: (state, updatedPermission) => {
    state.permissions.forEach((permission) => {
      if (permission.id == updatedPermission.id) {
        // Update Data
        permission.name = updatedPermission.name;
        permission.description = updatedPermission.description;
        permission.status = updatedPermission.status
      }
    });
  },
  removePermission: (state, id) => {
    state.permissions = state.permissions.filter((role) => role.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value
  }
};

const actions = {
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },

  /**
   * @description Get All permissions
   * @param {Number} data - permissions Object
   * @returns Promise
   */
  async fetchAllPermissions({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = { page: page, per_page: itemsPerPage, sort_by: sort_by, sort_order: sort_order };
      }
      let url = getQueryString(params).substring(1);
      axios.get(apiPath.permissions.getAllPermissions+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllPermissions", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Search Permissions
   * @param {Number} data - Permissions Object
   * @returns Promise
   */
  async searchPermission({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter
        params = { name, ...params };
      }
      let url = getQueryString(params).substring(1);
      axios.get(apiPath.permissions.searchPermissions+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllPermissions", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create New Role
   * @param {Object} data - Role Object
   * @returns Promise
   */
  createPermission({ commit, }, data) {
    return new Promise((resolve, reject) => {
      axios.post(apiPath.permissions.createPermission, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("addToPermission", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update Role Info
   * @param {Object} data - Update Role Object
   * @returns Promise
   */
  updatePermissionByID({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(apiPath.permissions.updatePermission+`${data._id}`, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("updatePermission", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove Role
   * @param {Number} id - Role Id
   * @returns Promise
   */
  async removePermissionById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(apiPath.permissions.removePermission + id, {})
        .then(async (res) => {
          if (res.data.success) {
            await commit("removePermission", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description On download button get data in excel sheet
   * @returns Promise
   */
  async getAllExcelSheetByDownload({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.permissions.downloadPermissions)
        .then((res) => {
          resolve(res);
          commit("doNothing");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
