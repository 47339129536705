import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  propertyProposalRequests: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllPropertyProposalRequests: (state) => state.propertyProposalRequests,
};

const mutations = {
  doNothing: (state) => state,
  setAllPropertyProposalRequests: (state, payload) => (state.propertyProposalRequests = payload),
  addToPropertyProposalRequests: (state, payload) => state.propertyProposalRequests.unshift(payload),
  updateToPropertyProposalRequests: (state, updatedData) => {
    state.propertyProposalRequests.forEach((PropertyProposalRequest) => {
      if (PropertyProposalRequest.id == updatedData.id) {
        // Update Data
        PropertyProposalRequest.full_name = updatedData.full_name;
        PropertyProposalRequest.email = updatedData.email;
        PropertyProposalRequest.mobile = updatedData.mobile;
        PropertyProposalRequest.city = updatedData.city;
        PropertyProposalRequest.street = updatedData.street;
        PropertyProposalRequest.state = updatedData.state;
        PropertyProposalRequest.pincode = updatedData.pincode;
        PropertyProposalRequest.role = updatedData.role;
      }
    });
  },
  removePropertyProposalRequests: (state, id) => {
    state.propertyProposalRequests = state.propertyProposalRequests.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value
  }
};

const actions = {
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All propertyProposalRequests
   * @param {Number} data - propertyProposalRequests Object
   * @returns Promise
   */

  async fetchAllPropertyProposalRequests({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.proposalRequest.getAllProposalRequest+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllPropertyProposalRequests", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  
  /**
   * @description Update propertyProposalRequests Profile
   * @param {Object} data - Update propertyProposalRequests Object
   * @returns Promise
   */
  updatePropertyProposalRequestById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.proposalRequest.updateProposalRequest+`${data.id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToPropertyProposalRequests", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Get Recieved Property by brand Requirement ID
   * @param {Number} id -  brand Requirement ID 
   * @returns Promise
   */
  fetchPropertyProposalRequestBrandReqID({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.proposalRequest.getAllProposalRequestByBrandReq+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },


  /**
   * @description Remove propertyProposalRequests
   * @param {Object} data - Remove propertyProposalRequests
   * @returns Promise
   */
  removePropertyProposalRequestById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.proposalRequest.removeProposalRequest+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removePropertyProposalRequests", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
