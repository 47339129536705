import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";

const state = {
  externalProperty: [],
};

const getters = {
  getExternalProperty: (state) => state.externalProperty,
};

const mutations = {
  setExternalProperty: (state, payload) => (state.externalProperty = payload),
  addToExternalProp: (state, payload) =>
    state.externalProperty.unshift(payload),
  removeExternalProperty: (state, id) => {
    state.externalProperty.splice(id, 1);
  },
  doNothing: (state) => state,
};

const actions = {
  /**
   * @description Get All external property
   * @param {Number} data - external property Object
   * @returns Promise
   */
  async fetchAllExternalProperty({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.externalProperty.getAllExternalProperty)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setExternalProperty", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create New brand requirement public property
   * @param {Object} data - User data Object
   * @returns Promise
   */
  createBrandRequirementPublicforExternal({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.externalProperty.createExternalPublicRequirement, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToExternalProp", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove External Properties
   * @param {Number} id - External Properties Id
   * @returns Promise
   */

  async removeExternalProperty({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.externalProperty.removeExternalProperty + `${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeExternalProperty", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Fetch External Property by id
   * @param {Number} id - External Properties Id
   * @returns Promise
   */

  async getExternalPropertyById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.externalProperty.getExternalPropertyByID + `${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
