import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  tags: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllTags: (state) => state.tags,
};

const mutations = {
  doNothing: (state) => state,
  setAllTags: (state, payload) => (state.tags = payload),
  addToTags: (state, payload) => state.tags.unshift(payload),
  updateToTags: (state, updatedData) => {
    state.tags.forEach((tag) => {
      if (tag.id == updatedData.id) {
        // Update Data
        tag.full_name = updatedData.full_name;
        tag.email = updatedData.email;
        tag.mobile = updatedData.mobile;
        tag.city = updatedData.city;
        tag.street = updatedData.street;
        tag.state = updatedData.state;
        tag.pincode = updatedData.pincode;
        tag.role = updatedData.role;
      }
    });
  },
  removeTags: (state, id) => {
    state.tags = state.tags.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value
  }
};

const actions = {
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All tags
   * @param {Number} data - tags Object
   * @returns Promise
   */

  async fetchAllTags({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.tags.getAllTags+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllTags", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
    * @description Get All Tag Properties
    * @param {Number} data - Tag Object
    * @returns Promise
    */

  async fetchAllTagProperties({ commit }, id) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (id.options.all) {
        params = id.options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = id.options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.tags.getAllTagProperties+`?&tag=${id.id}&${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllTags", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Update tags Profile
   * @param {Object} data - Update tags Object
   * @returns Promise
   */
  updateTags({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.tags.updateTag+`${data._id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToTags", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Search tags
   * @param {Number} data - tags Object
   * @returns Promise
   */

  searchTags({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }

      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.tags.searchTag+`?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllTags", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove tags
   * @param {Object} data - Remove tags
   * @returns Promise
   */
  removeTagById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.tags.removeTag+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeTags", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get tags Info by ID
   * @param {Number} id - tags ID
   * @returns Promise
   */
  fetchTagById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.tags.getTagByID+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create New tags 
   * @param {Object} data - User data Object
   * @returns Promise
   */
  createTags({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.tags.createTag, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToTags", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
