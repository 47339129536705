import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  industries: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllIndustries: (state) => state.industries,
};

const mutations = {
  doNothing: (state) => state,
  setAllIndustries: (state, payload) => (state.industries = payload),
  addToIndustries: (state, payload) => state.industries.unshift(payload),
  updateToindustries: (state, updatedData) => {
    state.industries.forEach((industry) => {
      if (industry.id == updatedData.id) {
        // Update Data
        (industry.name = updatedData.name),
          (industry.description = updatedData.description);
        industry.sub_industry = updatedData.sub_industry;
      }
    });
  },
  removeindustries: (state, id) => {
    state.industries = state.industries.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value;
  },
};

const actions = {
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All industries
   * @param {Number} data - industries Object
   * @returns Promise
   */

  async fetchAllIndustries({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.industries.getAllIndustries + `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllIndustries", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update industries Profile
   * @param {Object} data - Update industries Object
   * @returns Promise
   */
  updateIndustry({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.industries.updateIndustry + `${data._id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToindustries", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Search industries
   * @param {Number} data - industries Object
   * @returns Promise
   */

  searchIndustry({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
        let name = options.filter;
        params = { ...params, name };
      }

      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.industries.searchIndustries + `?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllIndustries", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove industries
   * @param {Object} data - Remove industries
   * @returns Promise
   */
  removeIndustryById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.industries.removeIndustry + `${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeindustries", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get industries Info by ID
   * @param {Number} id - industries ID
   * @returns Promise
   */
  fetchIndustryByID({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.industries.getIndustryByID + `${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create New industries
   * @param {Object} data - User data Object
   * @returns Promise
   */
  createIndustry({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.industries.createIndustry, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToIndustries", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
