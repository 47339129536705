import axios from "@/utils/axios";

const state = {
  allSmartSearch: [],
};

const getters = {
  getAllSmartSearch:  (state) => state.allSmartSearch,
};

const mutations = {
  setAllSmartSearch: (state, smartSearch) =>
    (state.allSmartSearch = smartSearch),
  doNothing: (state) => state,
};

const actions = {
  /**
   * @description Search Smart serach
   * @param {Object} data - Smart serach Object
   * @returns Promise
   */
  async smartSearchByPincode({ commit }, payload) {
    console.log(payload, "payload");
    return new Promise((resolve, reject) => {
      axios.get(
        `/v1/summary/pincode?pincode=${payload}`
      )
        .then(async (res) => {
          if (res.data.success) {
            await commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Search Smart serach
   *  @param {Object} data - Smart serach Object
   * @returns Promise
   */
  async smartSearchByAreaname({ commit }, payload) {
    console.log(payload, "payload");
    return new Promise((resolve, reject) => {
      axios.get(
        `/v1/summary/area_name?area_name=${payload}`
      )
        .then(async (res) => {
          if (res.data.success) {
            await commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
